/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function isIE() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // If Internet Explorer, return version number
        return true;
    else                 // If another browser, return 0
        return false;

    return false;
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          if(isIE() || navigator.userAgent.match(/Windows Phone/i) || (/Edge\/12./i.test(navigator.userAgent)) || (/Edge\/13./i.test(navigator.userAgent)) || (/Edge\/14./i.test(navigator.userAgent))){

              jQuery('body').addClass('IEbrowser');

          };

          $('.owl-carousel-home').owlCarousel({
              loop:false,
              items:1,
              nav:false
          });

          $('.owl-carousel-testimonial').owlCarousel({
              loop: true,
              items:1,
              mouseDrag: true,
              dots: false,
              nav:true,
              dotsSpeed: 600
          });

          $(".navbar-nav-mobile .menu-item-has-children").each(function() {
            sub_child = $(this).find('.dropdown-menu').children().length;
            if (sub_child > 0) {
              $(this).find('> a').append('<span class="expand ml-2"><i class="icon icon-keyboard_arrow_right "></i></span>');
            }
          });

          $('#site-mobile-menu .navbar-nav-mobile li .expand').click(function (e) {
              e.preventDefault();

              $(this).toggleClass('active');
              $(this).find('i').toggleClass('icon-keyboard_arrow_down');
              $(this).parent('a').parent('li').children('.dropdown-menu').slideToggle(100);
          });

          $('.hamburger-menu, .hamburger-menu-close, .nav-mobile-overlay').click(function () {
              $(".site-wrapper").toggleClass('open');
          });

          $(document).ready(function(){
              $('.image-popup-vertical-fit').magnificPopup({
                  type: 'image',
                  mainClass: 'mfp-with-zoom',
                  gallery:{
                      enabled:true
                  },

                  zoom: {
                      enabled: true,

                      duration: 300, // duration of the effect, in milliseconds
                      easing: 'ease-in-out', // CSS transition easing function

                      opener: function(openerElement) {

                          return openerElement.is('img') ? openerElement : openerElement.find('img');
                      }
                  }

              });

          });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page-id-81': {
      init: function() {
        // JavaScript to be fired on the about us page

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
